<template>
  <b-overlay :show="isFetching">
    <b-row>
      <b-col>
        <b-card>
          <div class="d-flex justify-content-between align-items-center mb-3">
            <h6>
              {{ `${$t('rewards.refill')} ` }}
            </h6>
            <b-button variant="primary" size="sm" @click="navigateRoute('')">
              <i class="uil-plus mr-1"></i>
              {{ $t('buttons.add') }}
            </b-button>
          </div>

          <b-table
            :items="refillPromotions"
            :fields="fields"
            responsive
            show-empty
          >
            <template #cell(#)="data">
              {{ data.index + 1 }}
            </template>
            <template #cell(createdAt)="data">
              {{ data.value | datetime }}
            </template>
            <template #cell(title)="data">
              {{ data.value || '-' }}
            </template>
            <template #cell(startDate)="data">
              {{ data.value | datetime }}
            </template>
            <template #cell(endDate)="data">
              {{ data.value | datetime }}
            </template>
            <template #cell(isActive)="data">
              <b-badge :variant="data.value ? 'success' : 'danger'">
                {{
                  data.value
                    ? $t('utils.status.active')
                    : $t('utils.status.inactive')
                }}
              </b-badge>
            </template>
            <template #cell(id)="data">
              <b-dropdown variant="info" size="sm" dropleft>
                <template #button-content>
                  <i class="uil uil-cog"></i>
                </template>
                <b-dropdown-item @click="onChangeRefillStatus(data.value)">
                  {{ $t('buttons.change_status') }}
                </b-dropdown-item>
                <b-dropdown-item @click="navigateRoute(data.value)">
                  <i class="uil uil-edit-alt mr-2"></i>
                  {{ $t('buttons.edit') }}
                </b-dropdown-item>
                <b-dropdown-item variant="danger" @click="onDelete(data.value)">
                  <i class="uil uil-trash-alt mr-2"></i>
                  {{ $t('buttons.remove') }}
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template #empty="">
              <p class="text-center text-muted">{{
                $t('messages.nothing_here')
              }}</p>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'PointSettings',
  components: {
    // RewardPointForm: () =>
    //   import('../../../components/forms/reward-point-form'),
  },
  props: {
    agentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isModalActive: false,
      selectedValue: {},
      fields: [
        '#',
        {
          key: 'createdAt',
          label: `${this.$t('fields.createdAt')}`,
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'title',
          label: `${this.$t('fields.title')}`,
          thStyle: {
            minWidth: '170px',
          },
        },
        {
          key: 'requiredRefills',
          label: 'เติมเงิน',
          class: 'text-right',
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'couponEarns',
          label: 'คูปองที่ได้รับ',
          class: 'text-right',
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'isActive',
          label: `${this.$t('fields.status')}`,
          class: 'text-center',
        },
        {
          key: 'id',
          label: '-',
          width: '100',
          class: 'text-right',
        },
      ],
      pointForm: {
        id: null,
        rewardPoint: 0,
        numberOfCondition: 0,
        active: false,
      },
    }
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.reward.isFetchingRefillPromotinos,
      isUpdating: (state) => state.reward.isUpdatingRewardPointSettings,
      rewardPointId: (state) => state.reward.rewardPointId,
      refillPromotions: (state) => state.reward.refillPromotions,
    }),
  },
  watch: {
    agentId(val) {
      if (val) {
        this.fetchData()
      }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions([
      'fetchRefillPromotions',
      'updateRewardPointSettings',
      'toggleRefillPromotionStatus',
    ]),
    fetchData() {
      if (this.agentId) {
        this.fetchRefillPromotions(this.agentId)
      }
    },
    toggleModal(value) {
      this.isModalActive = !this.isModalActive
      this.selectedValue = value
    },
    onCloseModal() {
      this.isModalActive = false
    },
    onUpdate(data) {
      this.updateRewardPointSettings({
        agentId: this.agentId,
        id: data.id,
        data,
      })
    },
    onChangeRefillStatus(conditionId) {
      this.toggleRefillPromotionStatus(conditionId)
    },
    navigateRoute(id) {
      if (id) {
        this.$router.push(
          `/rewards/refill-promotions/detail/${id}?agentId=${this.agentId}`
        )
      } else {
        this.$router.push(
          `/rewards/refill-promotions/create?agentId=${this.agentId}`
        )
      }
    },
  },
}
</script>
